@use "../abstracts/" as *;

.section-home {
    justify-content: center;
    height: 100vh;
    width: 100%;
    div {
    }

    .home-content {
        display: flex;
        justify-content: center;
        gap: 2em;
        align-items: flex-end;
        position: relative;
        width: 100%;

        .img-div {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: clamp(200px, 100%, 325px);
            aspect-ratio: 1;
            border-radius: 20px;
            border-radius: 50%;
            border: 2px solid rgba(0, 0, 0, 0.108);
            // overflow: hidden;
            
            img {
                position: absolute;
                height: 100%;
                // width: clamp(200px, 100%, 375px);
                border-radius: 50%;
            }
        }
        // glowing animation would go here.
    }


    .text-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding-left: 20px;

        h1 {
            font-size: clamp(2rem, 2em + 2vw, 4rem);
        }

        p {
            font-size: clamp(1em, 1em + 2vw, 2rem);
        }

        .text {
            position: relative;
        }

        .typed-text {
            position: absolute;
            font-size: clamp(1em, 1em + 2vw, 2rem);
            color: $color-yellow;
        }
    }

    a {
        margin: 100px auto 0 auto;
        padding: 8px 15px;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        border: none;
        color: black;
        border-radius: 20px;
        cursor: pointer;
        background-color: $color-yellow;
        transition: 250ms ease;
    }

    a:hover {
        background-color: $color-yellowHover;
    }

    @media (max-width: 1000px) {
        .home-content {
            flex-direction: column;
            align-items: center;

            .img-div {
                // width: 100%;
            }
        }
        .text-div {
            padding: 0;
        }

    }
}