.profile-page {
    display: flex;
    position: relative;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1500px;
        padding: 40px clamp(1em, 2vw,100px);

        @media (max-width: 1300px) {
            // padding: 40px 0;
        }
    }
}