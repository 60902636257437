/* font-family: 'Red Hat Text', sans-serif; */
/* font-family: 'Poiret One', cursive; */
/* font-family: 'Proza Libre', sans-serif; */
// font-family: 'Lato', sans-serif;
// font-family: 'Montserrat', sans-serif;


$font-nav: 'Lato', sans-serif;
$font-body: 'Lato', sans-serif;
$font-headers: 'Montserrat', sans-serif;
$font-size-headers: 2rem;
$font-quote: "Proza Libre", sans-serif;
$font-technologies: 'Lato', sans-serif;
$font-technologies-thin: 100;
$font-technologies-light: 300;
$font-description:'Lato', sans-serif;
$font-project-title: 'Montserrat', sans-serif;
$font-project-title-reg: 400;
$font-project-title-med: 500;
$font-project-title-bold: 700;
$font-project-title-exbold: 800;