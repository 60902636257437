@use "../abstracts/index" as *;

.fa-html5 {
    color: $color-html;
}
.fa-css3-alt {
    color: $color-css;
}
.fa-js {
    color: $color-Js;
}
.fa-react {
    color: $color-react;
}
.fa-node {
    color: $color-node;
}
.devicon-express-original {
    // color: $color-;
}
.fa-cloud {
    color: $color-cloud;
}
.devicon-mongodb-plain colored {
    color: rgb(161, 33, 33);
}
.fa-github {
    color: white;
}
.fa-database {
    color: $color-sql;
}
.devicon-python-plain {
    color: $color-python;
}
.devicon-django-plain {
    color: $color-django;
}
.devicon-heroku-original {
    color: $color-heroku;
}
.devicon-postgresql-plain {
    color: $color-postgresql;
}
.skill-icon {
    width: 50px;
}