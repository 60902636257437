@use "../abstracts/" as *;

.section-about {
    flex-direction: column;
    min-height: 100vh;

    .quote-div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50vh;

        .quote {
            position: relative;
            height: 200px;
            width: clamp(300px, 40vw, 100%);
            font-size: clamp(1rem, 1rem + 2vw, 2.5rem);
            z-index: 10;

            #quote::before {
                content: open-quote;
                position: absolute;
                font-size: clamp(10rem, 3rem + 15vw, 20rem);
                font-family: $font-quote;
                color: $color-accentOne;
                top: -5rem;
                left: -2rem;
                z-index: -1;
            }

            p {
                text-align: start;
            }

            p:nth-of-type(2) {
                float: right;
                color: $color-lightYellow;
                font-size: 2rem;
            }
        }

        @media (max-width: 1000px) {
            justify-content: center;

            .quote {
                width: 80%;
            }
        }
    }

    .about {
        &-cards {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }

        &-card {
            display: grid;
            place-items: center;
            margin: .7em;
            position: relative;
            padding: .5em;
            height: 400px;
            width: 250px;
            border-radius: 20px;
            text-align: justify;
            background-color: $color-cardBackground;
            overflow: hidden;
            transition: 500ms ease;
            cursor: pointer;

            img {
                position: absolute;
                height: 100%;
                user-select: none;
                transition: 500ms ease;
            }

            &-front {
                display: grid;
                place-items: center;
                position: relative;
                width: 70px;
                height: 100px;
                background-color: $color-cardOverlay;
                transition: 500ms ease;

                .title {
                    text-align: center;
                    line-height: 2rem;
                    user-select: none;
                    font-family: $font-project-title;
                    z-index: 100;
                }
            }

            &-front::before {
                content: "";
                position: absolute;
                right: -30px;
                width: 0;
                border-left: 30px solid $color-cardOverlay;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                transition: 500ms ease;

            }

            &-front::after {
                content: "";
                position: absolute;
                left: -30px;
                width: 0;
                border-right: 30px solid $color-cardOverlay;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                transition: 500ms ease;

            }

            &.active {}

            &-back {
                display: grid;
                place-items: center;
                position: relative;
                padding: 1rem;
                width: 100%;
                height: 100%;
                color: white;
                font-size: 1.2rem;
                border-radius: 15px;
                background-color: $color-cardOverlay;
                transition: 500ms ease;


                .description {
                    text-align: center;
                }
            }
        }

        @media (max-width: 800px) {
            
            &-card {
                width: 100%;
                
                img {
                    // height: 400px;
                    width: 100%;
                }
                
                &.active {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 800px) {
        margin-top: 2em;
    }
}