@use "../abstracts/" as *;

.nav {

    .hamburger-menu-div {
        display: none;
    }

    display: grid;

    position: sticky;
    grid-template-rows: 1.5fr 1.5fr 1fr;
    top: 0;

    width: 250px;
    min-height: 100vh;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.106);
    backdrop-filter: blur(1em);
    background: rgba(10, 10, 10, 0.65);
    box-shadow: 0 8px 32px 0 rgb(2, 4, 24);
    border-right: 2px solid rgba(255, 255, 255, 0.09);


    opacity: 1;
    z-index: 99;
    transition: width 500ms ease;


    &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            padding: 2em;
            position: absolute;
            width: 130%;
        }
    }

    &-btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        list-style: none;
        font-weight: 300;
        transition: 500ms ease;
    }

    li {
        margin: .4em;
    }

    &-btn {
        font-size: 1.5rem;
        cursor: pointer;
        color: $color-lightGrey;
        text-decoration: none;
        transition: 250ms ease;


        &:hover,
        &.active {
            color: white;
        }
    }

    .nav-socials {
        display: flex;
        // align-self: flex-start;
        align-items: center;
        flex-direction: column;
        position: relative;

        #link-github {
            border-radius: 50px;
        }

        .profile-link {
            position: relative;

            margin-bottom: .5em;

            font-size: 2rem;
            color: white;

        }

        // Animation ----------------------------------------
        // @keyframes color_change {
        //     25% {color: red;}
        //     50% {color: green;}
        //     75% {color: blue;}
        //     100% {color: red;}
        // }
        .resume {
            color: white;
            text-decoration: none;
            font-family: $font-headers;
            font-size: 1.6em;
            font-weight: 800;
        }

        :hover {
            transition: 250ms ease;
            color: $color-yellow;
            // animation: color_change 2s infinite;

        }
    }














    // mobile Styling ------------------------------------------------------------
    @media only screen and (max-width: 800px) {

        // set list to block,
        .hamburger-menu-div {
            display: block;

            position: absolute;

            padding: 2em;
            height: 100%;

        }

        .hamburger-menu::before,
        .hamburger-menu::after,
        .hamburger-menu {
            content: "";
            position: relative;
            width: 50px;
            height: 7px;
            border-radius: 15px;
            background-color: white;
        }

        .hamburger-menu::before {
            position: absolute;
            top: -.8em;
        }

        .hamburger-menu::after {
            position: absolute;
            bottom: -.8em;

        }

        display: flex;

        flex-direction: column;
        position: absolute;

        width: 100vw;
        min-height: 0;
        height: 75px;

        // background-color: palegreen;
        border-right: 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.09);

        transition: 250ms ease;


        &-logo {
            display: none;

            img {
                display: none;
            }
        }

        &-btns {
            
            .nav-btn {
                display: none;
                opacity: 0;
                transition: ease-in-out 2000ms;

            }
        }

        .nav-socials {
            display: none;
        }

        &.nav-open {

            height: 80vh;
            background-color: rgba(1, 1, 24, 0.59);

            & .nav-btns {
                display: flex;
                margin-top: 8em;
                margin-bottom: 2em;

                .nav-btn {
                    display: block;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    opacity: 1;
                    transition: ease-in-out 2000ms;
                }
            }

            & .nav-socials {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: flex-start;
                align-self: center;

                margin-top: 1em;
                width: 60%;
                
                i {font-size: 2.5rem;}

                p {font-size: 2.3rem;}
            }
        }
    }

}