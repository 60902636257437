@use "../abstracts/index.scss" as *;

.section-skills {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    > div {
        margin: 5em 0;
        width: 80%;
    }

    .skills {
        &-text {
            // width: clamp(350px, 60%, 60%);
            p {
                text-align: justify;
                font-size: clamp(1.2em, 1em + 1vw, 1.7em);
                font-family: $font-headers;
                // color: $color-lightYellow;
                color: white;
                line-height: 1.6em;
            }
        }
        &-list-div {
            display: flex;
            justify-content: center;
            align-items: center;

            .skills-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: flex-end;
                list-style: none;
                gap: 2em;
                i {
                    font-size: 3em;
                    // height: 80px;
                    margin-bottom: .25em;
                    &.fa-cloud {
                        font-size: 3.5em;
                    }
                }
                p {
                    font-size: clamp(1.2em, 2vw, 1.2em);
                }
                img {
                    height: 80px;
                }
                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 1em;
                }
            }
        }
    }
}