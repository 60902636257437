@use "./layouts/portfolio.scss";
@use "./components/navbar.scss";
@use "./sections/home.scss";
@use "./sections/about.scss";
@use "./sections/skills.scss";
@use "./sections/projects.scss";
@use "./sections/contact.scss";
@use "./icons/icons.scss";
@use "./abstracts/" as *;


*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    // background: url("../../public/img/background.png");
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-color: $color-background;
    
    height: 100vh;
    background-color: rgb(2,0,3);
    background-image: url(../../public/img/background_02.jpeg);
    background-repeat: no-repeat;
    background-size: cover;

    color: white;
    font-family: $font-body;
    @media screen and (max-width: 800px) {
        background-position: center;
        background-size: cover;
    }
}

.text-teal {
    color: rgb(94, 250, 255);
}

/*----------------------------------------
        CSS for ScrollBars
-----------------------------------------*/
::-webkit-scrollbar {
    // width: 0;
}
                    
::-webkit-scrollbar-track {
    // border-radius: 10px;
    // background-color: shidden;
}
                    
::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: rgb(32, 119, 32);
    // -webkit-box-shadow: inset 0 0 6px 3px rgba(0, 0, 0, 0.5); 
}
/*========== END SECTION ==========*/