$color-accentOne: rgb(139,133,109);
$color-yellow: rgb(255,222,89);
$color-yellowHover: rgb(197, 164, 30);
$color-lightYellow: rgb(237,221,157);
$color-cardBackground:rgb(75, 143, 149);
$color-cardOverlay: rgba(16, 20, 9, .7);
$color-lightGrey: rgb(146, 146, 146);
$color-greyaccent: rgb(63, 63, 63);
$color-background: rgb(27, 26, 26);

$color-html: rgb(221,75,37);
$color-css: rgb(36,75,221);
$color-react: rgb(95,211,243);
$color-Js: rgb(239,216,28);
$color-cloud: rgb(0,172,218);
$color-sql: rgb(206, 37, 37);
$color-python: rgb(54,110,157);
$color-django: rgb(37, 139, 112);
$color-heroku: rgb(119, 37, 129);
$color-postgresql: rgb(74, 153, 239);
$color-node: rgb(132,188,1);