@use "../abstracts/" as *;

.section-contact {
    flex-direction: column;
    gap: 2em;
    height: 100vh;
    > p {
        padding: 20px;
        text-align: center;
        max-width: 80%;
        font-size: clamp(1em, 1em + 2vw, 2.5em);
        font-family: $font-headers;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            display: block;
            margin-top: 20px;
            font-size: 1.3rem;
            color: $color-lightYellow;

            /*==============================
                Styling for chrome autocomplete.
            ==============================*/
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                -webkit-text-fill-color: $color-lightYellow;
                -webkit-box-shadow: 0 0 0px 100px rgb(59, 59, 75) inset;

            }

            input,
            textarea {
                display: block;
                margin-top: .5em;
                padding: 10px;
                width: clamp(300px, 50vw, 500px);
                min-height: 30px;
                font-size: 1rem;
                font-family: $font-body;
                color: $color-lightYellow;
                border: none;
                border-top: 3px solid $color-accentOne;
                background-color: rgba(59, 59, 75, 0.512);
                outline: none;
                transition: 500ms ease;

                &:focus {
                    box-shadow: 0 0 2px 2px $color-accentOne;
                }
            }
        }


        input[type="submit"] {
            margin: 20px;
            padding: 8px 15px;
            font-size: 1rem;
            font-weight: bold;
            border: none;
            border-radius: 20px;
            color: black;
            cursor: pointer;
            background-color: $color-yellow;
            transition: 250ms ease;
        }

        input[type="submit"]:hover {
            background-color: $color-yellowHover;
        }
    }
    @media (max-width: 800px) {
        margin-top: 4em;
    }
}