@use "../abstracts/" as *;

.section-projects {
    flex-direction: column;
    top: 0;
    padding: 0;

    width: 90%;

    div {}

    >div {
        width: 100%;
        // box-shadow: 0 0 1px 1px aquamarine;
    }

    .project {
        &-detail {
            display: grid;
            grid-template-columns: 30vw 1fr;
            padding: 2em;
            min-height: 400px;

            .placeholder-text {
                grid-column: 1/3;
                text-align: center;
                align-self: center;
            }



            .placeholder-text {
                font-size: $font-size-headers;
            }

            &-img {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: clamp(200px, 30vw, 500px);
                border-radius: 20px;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    height: 100%;
                }
            }

            &-details {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                position: relative;
                padding-left: clamp(1em, 5vw, 6em);

                .details-title {
                    font-size: clamp(1.3rem, 1.3rem + .5vw, 2rem);
                    color: $color-lightYellow;
                    font-family: $font-project-title;

                }

                .technologies-title,
                .collaborators-title {
                    font-size: clamp(1.3rem, 1.3rem + .1vw, 2rem);
                    color: $color-lightYellow;
                    font-family: $font-project-title;
                }

                .description {
                    margin: 10px 0 10px 0;
                    min-height: 200px;
                    text-align: justify;
                    color: white;
                    font-size: clamp(1rem, 1rem + .15vw, 2rem);
                    font-family: $font-description;
                    font-weight: $font-technologies-light;

                    ul {
                        // margin-top: 20px;
                    }
                }

                .collaborators {
                    display: flex;
                    list-style: none;
                    margin-bottom: 1.5em;

                    .collaborator {
                        font-size: 1.1em;
                        background-color: transparent;

                        &+.collaborator {
                            margin-left: 10px;
                        }

                        a {
                            color: $color-yellow;
                        }
                    }
                }

                .technologies {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    min-height: 75px;
                    list-style: none;
                    font-size: clamp(1rem, 1rem + .15vw, 2rem);
                    font-family: $font-description;
                    font-weight: $font-technologies-light;
                    color: white;

                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    li+li {
                        margin-left: 1em;
                    }
                }

                .links {
                    display: flex;
                    align-items: flex-end;
                    height: 50px;

                    // > div {
                    //     background-color: red;
                    //     height: 50px;
                    // }
                    .windup-message {
                        margin: 0 0 .5em 1em;
                        font-size: 1em;
                    }

                    a {
                        padding: 8px 15px;
                        font-size: 1rem;
                        font-weight: bold;
                        border: none;
                        color: black;
                        border-radius: 20px;
                        cursor: pointer;
                        text-decoration: none;
                        background-color: $color-yellow;
                        transition: 250ms ease;

                        &+a {
                            margin-left: 1em;
                        }

                        &:hover {
                            background-color: $color-yellowHover;
                        }
                    }
                }

            }

            @media (max-width: 1000px) {
                display: flex;
                flex-direction: column;
                align-items: center;

                // padding: 0;
                &-img {
                    height: 500px;
                    // width: calc(300px + 20vw);
                    width: 100%;

                    // border-radius: 0;
                    img {
                        border-radius: 20px
                    }

                    ;
                }

                .placeholder-text {
                    margin: auto;
                }

                &-details {
                    padding: 2em;
                }
            }
        }

        &-nav {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            padding-right: 2em;
            height: 75px;

            &-btns {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position: relative;
                margin: 5px 0;
                width: clamp(300px, 60%, 60%);
                border-bottom: 4px solid $color-accentOne;

                ::before {
                    content: "";
                    position: absolute;
                    left: -13px;
                    bottom: -9px;
                    padding: 3px;
                    transform: rotate(45deg);
                    background-color: transparent;
                    border: 4px solid $color-accentOne;
                }

                ::after {
                    content: "";
                    position: absolute;
                    right: -13px;
                    bottom: -9px;
                    padding: 3px;
                    transform: rotate(45deg);
                    background-color: transparent;
                    border: 4px solid $color-accentOne;
                }

                li+li {
                    border-left: 1px solid $color-accentOne;
                }
            }

            &-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;
                padding: 10px;
                height: 30px;
                font-size: 1.2rem;
                color: $color-lightGrey;
                cursor: pointer;
                user-select: none;

                &.active {
                    color: $color-lightYellow;
                }
            }

            &-bar {}
        }

        &-list {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 0 2em;
            margin-top: 2em;
            min-height: 300px;

            .projects-personal {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-family: $font-headers;
            }

            .project {
                &-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    margin: 1.2em 0;
                    width: clamp(200px, 40%, 500px);


                    .img-div {
                        height: 200px;
                        border-radius: 20px;
                        margin-bottom: 1em;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                        background-color: rgba(244, 238, 238, 0.51);
                        z-index: 50;

                        .title-shape-div {
                            display: grid;
                            place-items: center;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            // height: 300px;
                            position: absolute;
                        }

                        img {
                            position: absolute;
                            width: 500px;
                            height: 300px;
                        }

                    }

                    @media (max-width: 800px) {
                        width: 100%;

                        .img-div {

                            img {
                                top: -20%;
                                width: 100%;
                            }
                        }
                    }

                    .technologies {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        flex-wrap: wrap;
                        height: 25%;
                        color: white;
                        font-family: $font-description;
                        font-weight: $font-technologies-light;
                        list-style: none;
                        z-index: 98;

                        li {
                            margin: .25em 0;
                            padding: 0 .25em;
                            font-size: 1.1rem;
                        }

                        li+li {
                            // border-left: 2px solid $color-accentOne;

                        }
                    }

                    &-front {
                        display: grid;
                        place-items: center;
                        position: relative;
                        width: clamp(100px, 30%, 30%);
                        height: 75px;
                        background-color: $color-cardOverlay;
                        transition: 500ms ease;
                        z-index: 99;

                        .title {
                            position: absolute;
                            text-align: center;
                            font-size: 1.2rem;
                            line-height: 2rem;
                            font-family: $font-project-title;
                            font-weight: $font-project-title-reg;

                            user-select: none;
                            z-index: 100;
                        }
                    }

                    &-front::before {
                        content: "";
                        position: absolute;
                        right: -30px;
                        width: 0;
                        border-left: 30px solid $color-cardOverlay;
                        border-top: 37.5px solid transparent;
                        border-bottom: 37.5px solid transparent;
                        transition: 500ms ease;

                    }

                    &-front::after {
                        content: "";
                        position: absolute;
                        left: -30px;
                        width: 0;
                        border-right: 30px solid $color-cardOverlay;
                        border-top: 37.5px solid transparent;
                        border-bottom: 37.5px solid transparent;
                        transition: 500ms ease;

                    }

                    // &.active {
                    //     height: 300px;

                    // }

                    // &-back {
                    //     display: grid;
                    //     place-items: center;
                    //     position: relative;
                    //     padding: 1rem;
                    //     width: 100%;
                    //     height: 100%;
                    //     color: white;
                    //     font-size: 1.2rem;
                    //     border-radius: 15px;
                    //     background-color: $color-cardOverlay;
                    //     transition: 500ms ease;


                    //     .description {
                    //         text-align: center;
                    //     }
                    // }
                }

            }

        }
    }
}